import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import BalletWestImage from '../../assets/images/publication-banners/Ballet_West.jpg';

export const query = graphql`
  query BalletQuery {
    allBalletCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function BalletWest({ data }) {
  const balletNodes = data.allBalletCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/ballet-west/2024/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Ballet West - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Ballet West’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Ballet West</h1>
            </header>
            <span className="image main">
              <img
                src={BalletWestImage}
                alt="Ballet West program covers and Capitol Theatre venue"
              />
            </span>
            <p className="box">
              With over 100,000 playbills published every season, Ballet West
              ranks among the top professional ballet companies in North
              America. Every year, The Nutcracker returns to wow the crowds.
              Invest in an upscale audience that enjoys exceptional artistry,
              beauty, and elegance.
            </p>
            <ul className="actions">
              <li>
                <a href="https://balletwest.org/" className="button special">
                  <i className="icon fa-globe"> </i>Ballet West Website
                </a>
              </li>
            </ul>
            <h2>
              Ballet West <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the array, and maps/creates a new array from every element in the called array. */}
              {balletNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image}
                  />
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <br />
                  <h5>Advertising Information</h5>
                  <section className="box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            File Ready Deadline: <br />
                            {node.FR}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                        <tr>
                          <td>Print Quantity: {node.Quantity}</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

import React from 'react';

const SeasonYear = () => {
  return (
    <span>
      {new Date().getFullYear()}–{new Date().getFullYear() + 1}
    </span>
  );
};

export default SeasonYear;
